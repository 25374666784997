.e-contentblocks {
  &-funnelview_list {
    display: flex;
    min-width: size("base", 100);
  }

  &-funnelview {
    display: inline-flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: size("base", 50);
    padding: $space-m 0;
    text-align: center;
    width: auto;

    &__device {
      margin-bottom: auto;
      margin-top: auto;
    }

    &__actions {
      margin-top: auto;
    }
  }
}
