$mce-icon: (
  "bold": "format-bold",
  "italic": "format-italic",
  "underline": "format-underline",
  "strikethrough": "format-strikethrough",
  "link": "link",
  "linkEditor": "link",
  "outerLinkEditor": "link",
  "personalization": "profile",
  "removeformat": "format-clear",
  "conditionalEditor": "conditional-text",
  "customEslEditor": "code",
  "generativeAi": "feature-tactics",
  "resetEditable": "reset",
);
$mce-icon-old: (
  "bold": "horizon/e-bold",
  "italic": "horizon/e-italic",
  "underline": "horizon/e-underline",
  "strikethrough": "horizon/e-strikethrough",
  "link": "horizon/link",
  "linkEditor": "horizon/link",
  "outerLinkEditor": "horizon/link",
  "personalization": "horizon/e-profile",
  "removeformat": "horizon/e-clear-format",
  "conditionalEditor": "horizon/e-conditional_text",
  "customEslEditor": "horizon/code",
  "generativeAi": "horizon/e-feature-tactics",
  "resetEditable": "horizon/e-reset",
);

.mce {
  &-ico {
    font-style: normal;

    &::before {
      color: var(--token-icon-default-text);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-sizeSmall);
      font-weight: normal;
      height: auto;
    }
  }

  &-i {
    @each $class, $data in $mce-icon {
      &-#{$class}:before {
        content: var(--token-glyphs-#{$data});
      }
    }
  }

  &-btn:not(.mce-disabled) {
    button:hover,
    &.mce-active button {
      .mce-ico {
        &::before {
          color: var(--token-neutral-000);
        }
      }
    }
  }
}
