.e {
  &-contentblocks {
    &-dragview {
      @include typography("body");

      background: var(--token-highlight-default);
      color: var(--token-neutral-000);
      min-width: size("base", 25);
      padding: $space-xs $space-s;

      .e-icon {
        fill: var(--token-neutral-000);
        margin-right: $space-s;
      }
    }
  }
}
