cb-campaign-link-overview-item,
cb-link-overview-item {
  display: block;
  padding: $space-m;

  &:hover {
    .cb-actions_on_hover {
      visibility: visible;
    }
  }
}

.cb {
  &-actions_on_hover {
    visibility: hidden;
  }
}
