.cb {
  &-emptystate {
    pointer-events: none;
    z-index: 2;

    .e-btn {
      pointer-events: all;
    }
  }
}
