.e {
  &-contentblocks {
    &-block_level_variables {
      background: var(--token-neutral-000);
      border: 1px solid var(--token-box-default-border);
      border-radius: $border-radius;
      bottom: -1px;
      height: auto;
      left: -1px;
      opacity: 0;
      position: absolute;
      right: -1px;
      top: -1px;
      transform: translateX(-120%);
      transition: all 0.4s ease-in-out;
      width: auto;
      z-index: 3;

      &-open {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }
}
