.e {
  &-contentblocks {
    &-preview_section {
      position: relative;

      &-dual {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -348px;
      }
    }

    &-navigation_section {
      position: relative;
    }

    &-blocktemplatelist {
      width: 100%;
    }

    &-block_template_source {
      position: relative;
    }

    &-language_selector {
      display: block;
    }
  }
}
