cb-code-mirror {
  display: block;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

cb-block-template-source {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // height: 100%;
}

.cb-flex {
  display: flex;
  flex-direction: column;
  position: relative;
}

.cb-frameeditor {
  flex-grow: 1;
  position: relative;

  cb-code-mirror {
    bottom: 0;
    position: absolute;
    top: 0;
  }
}

.cb-codemirror-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  .CodeMirror {
    //  stylelint-disable declaration-no-important
    height: 100% !important;
  }
}
