.cb-token {
  background-color: var(--token-blue-600);
  border-radius: 0.25em;
  color: var(--token-neutral-000);
  cursor: pointer;
  display: inline-block;
  line-height: 1.4em;
  margin: 0 $space-3xs;
  padding: 0 $space-2xs;

  &-conditional {
    background-color: var(--token-green-500);
  }
}

vce-codemirror .e-label {
  margin-right: 0;
}
