.e-contentblocks-fullheight {
  &__content {
    &-responsive.e-verticalnav {
      > .e-verticalnav__content-fullheight {
        box-shadow: none;
      }

      + .e-fullheight__content-box {
        margin-left: -(size("base", 42));
      }
    }
  }
}
