.e {
  &-contentblocks {
    &-preview {
      background: transparent;
      bottom: 0;
      height: calc(100% - #{size-unitless("base", 7) + 1}px);
      overflow: hidden;
      position: absolute;
      top: #{size-unitless("base", 7) + 1}px;
      width: 100%;
      z-index: 2;

      &__iframe {
        background: var(--token-box-default-background);
        border: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        box-shadow: none;
        display: block;
        height: 100%;
        left: 0;
        margin: 0 auto;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        &-mobile {
          width: size("base", 46);
        }
      }

      &-sidebyside {
        .e-grid {
          height: 100%;
        }

        .e-cell {
          position: relative;
        }
      }
    }
  }
}
