// stylelint-disable declaration-no-important
.e-cb-personalization {
  &-selector-wrapper {
    border: 1px solid var(--token-box-default-border) !important;
    left: 0 !important;
    position: absolute !important;

    &-top {
      position: absolute !important;
      top: size("base", 1);
      transform: translateY(-100%);
    }
  }

  &-selector {
    background: var(--token-neutral-000) !important;
    padding: $space-xs !important;
    width: size("base", 38);

    .e-field {
      margin: 0 0 $space-xs !important;
      position: relative !important;
    }

    e-icon {
      bottom: 0;
      height: size("icon", "medium");
      left: 6px;
      margin: auto;
      position: absolute;
      top: 0;

      svg {
        height: size("icon", "medium");
        width: size("icon", "medium");
      }
    }

    .e-input {
      border: 1px solid var(--token-border-default);
      box-sizing: border-box;
      color: var(--token-neutral-500);
      height: size("base", 5);
      padding: 0 $space-m 0 $space-xl;
      width: 100%;

      &:focus {
        border-color: var(--token-highlight-default);
        box-shadow: none;
      }
    }

    .list {
      @include typography("body", true);

      border: 1px solid var(--token-border-default);
      max-height: size("base", 25);
      min-width: size("base", 35);
      overflow-y: scroll;
    }

    .category {
      @include typography("body", true);

      align-items: center;
      box-sizing: border-box;
      display: flex;
      font-weight: 500 !important;
      height: size("base", 4);
      padding: 0 $space-xs;
    }

    .personalization {
      @include typography("body", true);

      cursor: pointer;
      padding: $space-xs $space-xs $space-xs $space-l;

      &:hover {
        background: var(--token-blue-400);
        padding: $space-xs $space-xs $space-xs $space-l;

        .title {
          color: var(--token-neutral-000) !important;
        }

        .twig {
          color: var(--token-neutral-000) !important;
        }
      }

      &.selected {
        background-color: var(--token-highlight-default);

        .title {
          color: var(--token-neutral-000) !important;
        }

        .twig {
          color: var(--token-neutral-000) !important;
        }
      }

      .title {
        @include typography("body", true);
      }

      .twig {
        @include typography("body", true);

        color: var(--token-neutral-300) !important;
        font-style: italic;
        margin-left: $space-s;
      }
    }

    .no-match {
      font:
        13px Roboto,
        sans-serif !important;
      padding: $space-s;
    }
  }
}

.e-dropdown__content-sticky {
  .e-personalization-selector {
    border: 0;
  }
}

cb-personalizable-input {
  display: block;

  .e-dropdown__content {
    margin-bottom: -1px;
  }
}

.e-dialog .e-cb-personalization-selector {
  padding: $space-xs 0 !important;
  width: auto;
}
// stylelint-enable declaration-no-important
