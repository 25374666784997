.e {
  &-contentblocks {
    &-message {
      color: var(--token-neutral-300);
      font-style: italic;
    }

    &-toolbox {
      border: 1px solid var(--token-box-default-border);
      border-bottom-width: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .e-cell-small {
        padding: $space-3xs;
      }

      .e-grid {
        padding: $space-3xs;
      }
    }
  }
}
