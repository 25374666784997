.e-contentblocks {
  &-toolbar {
    @include clearfix;

    background: color-mix(in srgb, var(--token-overlay-dark) 50%, transparent);
    padding: $space-2xs $space-2xs 0;

    &__title {
      color: var(--token-text-light);
      padding: 0 $space-s;
      white-space: nowrap;
    }

    &__actions {
      float: right;
    }
  }

  &-toolbar:not(.toolbar),
  &-image_toolbar {
    background: var(--token-box-default-background);
    border: 1px solid var(--token-border-default);
    box-shadow: var(--token-shadow-medium);
    display: block;
    padding: $space-xs;

    .e-btn {
      border-color: transparent;
      border-radius: 0;

      .e-icon {
        color: var(--token-text-default);
      }

      &:hover,
      &:active,
      &:focus {
        background-color: var(--token-highlight-default);
        box-shadow: none;
        color: var(--token-neutral-000);

        .e-icon {
          color: var(--token-neutral-000);
        }
      }
    }
  }
}

[data-vcepositioner][x-placement*="bottom"] .e-contentblocks-toolbar {
  padding: 0 $space-2xs $space-2xs;
}
