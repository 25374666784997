/* stylelint-disable declaration-no-important */
.mce {
  &-container,
  &-widget,
  &-reset {
    @include ui-typo;

    * {
      background: transparent;
      border: 0;
      box-sizing: content-box;
      color: var(--token-neutral-900);
      cursor: inherit;
      direction: ltr;
      float: none;
      height: auto;
      margin: 0;
      max-width: none;
      outline: 0;
      padding: 0;
      position: static;
      -webkit-tap-highlight-color: transparent;
      text-align: left;
      text-decoration: none;
      text-shadow: none;
      vertical-align: top;
      white-space: nowrap;
      width: auto;
    }
  }

  &-edit-focus {
    outline: 3px dashed color-mix(in srgb, var(--token-neutral-800) 80%, transparent);
  }

  &-btn-group:not(:first-child) {
    border-left: 1px solid var(--token-box-default-border);
    margin-left: $space-2xs;
    padding-left: $space-2xs;
  }

  &-panel {
    background: var(--token-box-default-background);
  }

  &-tinymce-inline {
    background: transparent;
    height: auto !important;
    z-index: 500 !important;

    > .mce-container-body {
      background: var(--token-box-default-background);
      border: 1px solid var(--token-box-default-border);
      box-shadow: var(--token-shadow-medium);
      padding: 5px;
    }
  }

  &-btn {
    display: inline-block;

    button {
      @include e-btn;

      background: transparent;
      box-sizing: border-box;
    }

    &.mce-disabled {
      button {
        cursor: default !important;
        opacity: 0.5;

        &:hover {
          background: inherit;
          box-shadow: none;
          color: var(--token-neutral-800);
        }
      }

      &:not(.mce-btn-has-text) {
        button[type="button"]:hover {
          background-color: var(--token-neutral-000);
        }
      }
    }

    &.mce-active button {
      background-color: var(--token-highlight-default);
      box-shadow: none;
    }

    &:not(.mce-btn-has-text) {
      button[type="button"] {
        @include e-btn-onlyicon;

        border-color: transparent;

        &:hover {
          background-color: var(--token-highlight-default);
        }
      }

      &.mce-btn-active button[type="button"] {
        background-color: var(--token-highlight-default);
      }
    }

    &.mce-primary.mce-btn-has-text {
      button[type="button"] {
        @include e-btn-style("primary", $btn-styles);

        .mce-txt {
          color: currentcolor;
        }
      }
    }
  }

  &-toolbar.mce-first {
    border-bottom: 1px solid var(--token-border-default);
    margin-bottom: 0;
    padding: $space-2xs;
  }

  &-toolbar.mce-last {
    padding: $space-2xs;
  }

  &-tooltip {
    padding: $space-xs;
    position: absolute;
  }

  &-tooltip-inner {
    @include typography("small");

    background-color: var(--token-background-dark);
    color: var(--token-text-light);
    -webkit-font-smoothing: subpixel-antialiased;
    max-width: 200px;
    padding: $space-xs $space-xs $space-2xs;
    text-align: center;
    white-space: normal;
  }

  &-tooltip-arrow {
    border: 5px solid var(--token-background-dark);
    height: 0;
    line-height: 0;
    position: absolute;
    width: 0;
  }

  &-tooltip-n .mce-tooltip-arrow {
    border-bottom-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top: none;
    left: 50%;
    margin-left: 0;
    top: 0;
    transform: translateX(-50%) translateY(3px);
  }

  &-flow-layout-item {
    display: inline-block;
  }

  &-ico {
    font-size: 0;
    vertical-align: middle;

    &::before {
      content: "";
      display: inline-block;
      height: size("icon", "small");
      vertical-align: middle;
      width: size("icon", "small");
    }
  }

  &-i-remove::before {
    @include typography("body");

    content: "X";
  }

  &-floatpanel {
    position: absolute;

    &[role="dialog"],
    &[role="alertdialog"] {
      background: var(--token-box-default-background);
      border: 1px solid var(--token-box-default-border);
      box-shadow: var(--token-shadow-medium);
      left: 0 !important;
      margin: auto;
      position: fixed;
      right: 0 !important;

      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      .mce-close {
        cursor: pointer;
        position: absolute;
        right: size("base", 2);
        top: size("base", 2);

        .mce-i-remove::before {
          color: var(--token-icon-default-text);
          content: var(--token-glyphs-close);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-size);
        }
      }

      > .mce-reset {
        width: size("base", 44);
      }

      .mce-title {
        @include typography("heading3");

        flex: 0 0 auto;
        min-height: size("base", 7);
        padding: $space-s $space-4xl $space-s $space-m;
        position: relative;
      }

      .mce-textbox {
        @include e-input-old;

        display: inline-block !important;
        height: size("base", 4) !important;
        max-height: none !important;
        width: 100% !important;
      }

      .mce-window-body {
        margin-bottom: $space-m;
        padding: 0 $space-m;
      }

      .mce-foot {
        height: auto !important;

        > .mce-container-body {
          @include typography("body");

          border-top: 1px solid var(--token-box-default-border);
          display: flex;
          height: auto !important;
          padding: $space-m;
          width: 100% !important;
        }

        .mce-first {
          margin-left: $space-m;
        }

        .mce-last {
          order: -1;
        }
      }

      .mce-colorpicker {
        display: inline-block;
        height: 242px;
        position: relative !important;
        width: 202px;

        ~ .mce-form {
          display: inline-block;
          left: 20px !important;
          padding-left: 0;
          position: relative;

          .mce-textbox {
            left: $space-m !important;
          }

          .mce-abs-layout-item {
            position: absolute;
            width: size("base", 10) !important;

            .mce-container-body {
              background: inherit;
              width: size("base", 12) !important;
            }
          }

          label.mce-abs-layout-item {
            width: size("base", 2) !important;
          }
        }
      }
    }
  }

  &-colorbutton {
    .mce-preview {
      background: var(--token-neutral-500);
      display: block;
      height: 4px;
      left: 50%;
      margin: $space-xs 0 (-$space-s);
      overflow: hidden;
      padding-right: 0;
      position: absolute;
      top: 50%;
      transform: translateX(-50%);
      width: 20px;
    }

    button {
      @include e-btn;

      border: 0;
      min-width: 0;
      position: relative;

      &:hover {
        .mce-i-forecolor::before {
          color: var(--token-neutral-000);
        }
      }
    }

    .mce-open {
      @include e-btn-onlyicon;

      width: var(--token-icon-default-sizeSmall) !important;

      &::after {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-caret-down);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
      }

      &:hover {
        &::after {
          color: var(--token-neutral-000);
        }
      }
    }

    &.mce-active {
      .mce-i-forecolor::before {
        color: var(--token-neutral-000);
      }

      .mce-open {
        &::after {
          color: var(--token-neutral-000);
        }
      }
    }

    .mce-caret {
      display: none !important;
    }
  }

  &-i-forecolor {
    line-height: 1;

    &::before {
      @include typography("body");

      content: "A";
      margin: -$space-xs 0 0 $space-xs;
      vertical-align: baseline;
    }
  }

  &-colorbutton-grid {
    border-collapse: separate;
    border-spacing: 2px;
    margin: $space-2xs;

    td.mce-grid-cell div {
      border: 1px solid var(--token-neutral-300);
      cursor: pointer;
      height: size("base", 2);
      line-height: 15px;
      margin: 0;
      text-align: center;
      width: size("base", 2);

      &:focus {
        border-color: var(--token-highlight-500);
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }

    .mce-custom-color-btn {
      button {
        width: 100% !important;

        &:hover {
          color: var(--token-neutral-000);
        }
      }
    }
  }

  &-colorpicker {
    &-sv {
      border: 1px solid var(--token-neutral-500);
      cursor: crosshair;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 90%;
    }

    &-h {
      border: 1px solid var(--token-neutral-999);
      box-sizing: content-box !important;
      cursor: crosshair;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 6.5%;

      &-marker {
        background: var(--token-neutral-000);
        border: 1px solid var(--token-neutral-999);
        box-sizing: content-box !important;
        height: 4px;
        left: -1px;
        margin-top: -$space-2xs;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
      }

      &-chunk {
        width: 100%;
      }
    }

    &-overlay1,
    &-overlay2 {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-overlay1 {
      background: linear-gradient(
        to right,
        var(--token-neutral-000),
        color-mix(in srgb, var(--token-neutral-000) 0%, transparent)
      );
    }

    &-overlay2 {
      background: linear-gradient(
        to bottom,
        color-mix(in srgb, var(--token-neutral-999) 0%, transparent),
        var(--token-neutral-999)
      );
    }

    &-selector1 {
      background: none;
      border: 1px solid var(--token-neutral-999);
      border-radius: 50%;
      height: 12px;
      margin: (-$space-xs) 0 0 (-$space-xs);
      position: absolute;
      width: 12px;
    }

    &-selector2 {
      border: 1px solid var(--token-neutral-000);
      border-radius: 50%;
      height: 10px;
      position: absolute;
      width: 10px;
    }
  }

  &-multiline {
    white-space: normal;
  }

  &-menubtn.mce-listbox {
    button {
      border: none;
      display: flex;
      margin-right: $space-2xs;
      padding-right: $space-xl;
      position: relative;
      text-align: left;

      &::after {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-caret-down);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        position: absolute;
        right: $space-xs;
      }

      .mce-txt {
        @include typography("body");

        align-self: center;
        color: var(--token-text-default);
      }
    }

    &.mce-active button {
      border-color: var(--token-highlight-default);
      box-shadow: none;

      &::after {
        color: var(--token-neutral-000);
        content: var(--token-glyphs-caret-down);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
      }

      .mce-txt {
        color: var(--token-neutral-000);
      }
    }
  }

  &-stack-layout[role="menu"] {
    border: 1px solid var(--token-box-default-border);
    box-shadow: var(--token-shadow-medium);
    margin-left: 1px;
    max-height: size("base", 38);
    overflow: auto;
    overflow-x: hidden;

    .mce-menu-item {
      cursor: pointer;
      font-size: 0;
      height: 35px;
      line-height: 35px;
      padding-left: $space-m;

      > i {
        display: none;
      }

      &:hover {
        background-color: var(--token-neutral-200);
      }

      &.mce-active {
        background-color: var(--token-highlight-default);

        .mce-text {
          color: var(--token-neutral-000);
        }
      }

      .mce-text {
        @include typography("body");
      }
    }
  }
}

#mce-modal-block {
  background: color-mix(in srgb, var(--token-neutral-999) 50%, transparent);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

/* stylelint-enable declaration-no-important */
