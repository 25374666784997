//  stylelint-disable declaration-no-important
.e {
  &-contentblocks {
    &-contact_preview {
      &_section {
        height: 100%;
        padding: 0;
      }

      &_section_container {
        overflow: hidden;
      }

      &__email_basics_switch {
        position: absolute;
        right: 53px;
        top: 15px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__email_basics {
        background: var(--token-box-alternate-background);
        border-bottom: 1px solid var(--token-box-default-border);
        font-size: typo("small", "size");
        padding: $space-s;

        tr td:first-child {
          font-weight: bold;
          padding-right: $space-s;
        }
      }

      &__preview {
        flex: 0 1 100%;
      }
    }
  }
}
// stylelint-enable declaration-no-important
